<template>
  <PrimePage titleAlignment="left" :loading="isLoading" :backToNamedRoute="'ProgramDashboard'" :backText="'Back to Program Overview'">
    <ion-row class="full-height">
      <ion-col>
        <ion-content>
          <div class="prime-full-width-container prime-roster-container">
            <ion-row class="display-flex valign margin-top-twenty">
              <ion-col>
                <h4 class="view-title">
                  <span v-if="programName">{{ programName }} -</span> Program Roster
                </h4>
              </ion-col>
              <ion-col></ion-col>
              <ion-col size="auto" v-if="$can(I.MODIFY_ACCOUNT_PROGRAM)">
                <button class="prime-button button-primary" @click="goToNamedRoute('ProgramAddPatients')">
                  <IconPersonAdd class="icon-small" />
                  Add Patients
                </button>
              </ion-col>
              <ion-col size="auto" v-else>
                <button class="prime-button disabled button-primary" v-tooltip.top-start="{ content: $orgMissingPermissions('The Program Modification') }">
                  <IconPersonAdd class="icon-small" />
                  Add Patients
                </button>
              </ion-col>
              <ion-col size="auto">
                <button class="prime-button button-secondary pad-lr-ten margin-left-ten" @click="goToNamedRoute('RosterProgramEligiblePatients')">
                  View Eligible Patients
                </button>
              </ion-col>
            </ion-row>
            <ion-card color="white">
              <div v-if="isLoading" class="text-center pad-ten loading-overlay center-center fade-in">
                <ion-spinner name="lines" class="spinner-large" color="dark"></ion-spinner>
              </div>
              <ion-card-header>
                <div class="searchBox">
                  <form>
                    <ion-grid class="full-width">
                      <ion-row>
                        <ion-col size="2" class="margin-right-ten">
                          <input class="form-control" type="text" placeholder="Search for patient..." v-model="searchPatient" @focus="isTooltipOpen = true" @blur="isTooltipOpen = false" v-tooltip="{ content: 'You can search patient name, ID or date of birth. <br/>For date of birth, use format MM/DD/YYYY.', show: isTooltipOpen, trigger: 'manual' }" @input="debounceSearch()" />
                        </ion-col>
                        <ion-col size="2" class="margin-right-ten">
                          <input class="form-control" type="text" placeholder="Search for provider..." v-model="searchPCP" @input="debounceSearch()" />
                        </ion-col>
                        <ion-col></ion-col>
                        <ion-col size="auto">
                          <button class="prime-button button-secondary prime-roster-download-button" @click="exportRoster()" type="button" :class="{ 'button-pending': isDownloading }">
                            <ion-spinner name="lines" class="spinner-small"></ion-spinner>
                            <span v-if="!isDownloading"><IconDownload class="icon-small" />Download Roster </span>
                          </button>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </form>
                </div>
              </ion-card-header>
              <div>
                <div v-if="isTableLoading" class="text-center pad-ten loading-overlay center-center fade-in margin-top-ten">
                  <ion-spinner name="dots"></ion-spinner>
                </div>
                <vue-good-table
                  ref="vgt-table"
                  mode="remote"
                  :pagination-options="{
                    enabled: true
                  }"
                  :totalRows="totalRecords"
                  :columns="columns"
                  :rows="rows"
                  styleClass="prime-table vgt-table"
                  :search-options="{
                    enabled: false,
                    placeholder: 'Search Patients'
                  }"
                  :sort-options="{
                    enabled: true
                  }"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                >
                  <template slot="table-column" slot-scope="props">
                    <span v-if="props.column.field === 'enrollmentStatus'">
                      {{ props.column.label }}
                      <Tooltip :text="$copy.enrollmentStatusTooltip" fullWidth />
                    </span>
                    <span v-else>{{ props.column.label }}</span>
                  </template>
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'dob'">
                      {{ props.row.dob | moment("M/DD/YYYY") }}
                    </span>
                    <span v-else-if="props.column.field == 'beneLastName'">
                      {{ makeName(props.row) }}
                    </span>
                    <span v-else-if="props.column.field == 'pcpLastName'">
                      {{ makePcpName(props.row) }}
                    </span>
                    <span v-else-if="props.column.field == 'overallOutreachStatus' && props.row.overallOutreachStatus" class="button-like-status"> {{ props.row.overallOutreachStatus }} </span>
                    <span v-else-if="props.column.field == 'needsResponse'" class="text-center text-bold pill" :class="{ 'yellow-pill': props.row.needsResponse }">
                      {{ props.row.needsResponse ? "YES" : "NO" }}
                    </span>
                    <span v-else-if="props.column.field == 'optInDate'">
                      <span v-if="props.row.optInDate">
                        {{ props.row.optInDate | moment("M/D/YYYY - h:mmA") }}
                      </span>
                      <span v-else>
                        --
                      </span>
                    </span>
                    <span v-else-if="props.column.field == 'optOutDate'">
                      <span v-if="props.row.optOutDate">
                        {{ props.row.optOutDate | moment("M/D/YYYY - h:mmA") }}
                      </span>
                      <span v-else>
                        --
                      </span>
                    </span>
                    <span v-else-if="props.column.field == 'patientOptionsButton'">
                      <button @click="showPopover($event, props.row)" class="prime-button button-clear">
                        <ion-icon name="md-more"></ion-icon>
                      </button>
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </ion-card>
          </div>
        </ion-content>
      </ion-col>
    </ion-row>
  </PrimePage>
</template>

<script>
import PrimePage from "@/components/Global/PrimePage";
import { addIcons } from "ionicons";
import { add, more } from "ionicons/icons";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";
import PopoverRosterProgram from "@/components/Roster/PopoverRosterProgram";
import _ from "lodash";
import IconDownload from "@/components/Global/Icons/IconDownload";
import Tooltip from "@/components/Global/Tooltip";
import IconPersonAdd from "@/components/Global/Icons/IconPersonAdd";

addIcons({
  "md-add": add.md,
  "md-more": more.md
});

export default {
  name: "RosterProgram",
  components: {
    PrimePage,
    IconDownload,
    Tooltip,
    IconPersonAdd
  },
  data() {
    return {
      title: "Patient Roster",
      columns: [
        {
          label: "Patient Name",
          field: "beneLastName",
          tdClass: "bold",
          sortable: true
        },
        {
          label: "Date of Birth",
          field: "dob",
          width: "160px",
          sortable: true
        },
        {
          label: "ID",
          field: "mrn",
          width: "100px",
          sortable: true
        },
        {
          label: "Provider",
          field: "pcpLastName",
          width: "200px",
          sortable: true
        },
        {
          label: "Overall Outreach Status",
          width: "200px",
          field: "overallOutreachStatus",
          sortable: true
        },
        {
          label: "Open Task",
          field: "needsResponse",
          sortable: true
        },
        {
          label: "Added On",
          field: "optInDate",
          sortable: true
        },
        {
          label: "Removed On",
          field: "optOutDate",
          sortable: true
        },
        {
          label: "",
          field: "patientOptionsButton",
          thClass: "prime-roster-nosort",
          tdClass: "prime-roster-options-button-container",
          width: "35px",
          sortable: false
        }
      ],
      rows: [
        // row data will get injected here
      ],
      isDownloading: false,
      isLoading: false,
      isTableLoading: false,
      searchPatient: "",
      searchPCP: "",
      searchEnrollmentStatus: "",
      serverParams: {
        id: "0",
        page: "1",
        perPage: "10",
        columnFilters: { enrollmentStatus: "" },
        sort: []
      },
      totalRecords: 0,
      programName: undefined,
      isTooltipOpen: false
    };
  },
  mounted() {
    if (this.$route.query["status"]) {
      this.serverParams.columnFilters.enrollmentStatus = this.$route.query["status"];
      this.filterByStatus(this.$route.query["status"]);
    } else {
      this.serverParams.columnFilters.enrollmentStatus = "";
      this.filterByStatus();
    }

    EventBus.$on("reloadRosterView", () => {
      this.loadItems(true, false);
    });

    this.getProgramName(this.$route.params.program_id);
  },
  methods: {
    getProgramName(programId) {
      const method = "get";
      const path = document.config.programsApi + programId;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.programName = result.data.name;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "ERROR: Could not get program name.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    debounceSearch: _.debounce(function() {
      this.updateParams({ page: 1, columnFilters: this.buildSearch() });
      this.loadItems(false, true);
    }, 750),
    buildSearch() {
      let colFilters = {};
      if (this.searchPatient.length > 0) {
        colFilters.beneFirstName = this.searchPatient;
        colFilters.beneLastName = this.searchPatient;
        colFilters.mrn = this.searchPatient;
        colFilters.dob = this.searchPatient;
      }
      if (this.searchPCP.length > 0) {
        colFilters.pcpTitle = this.searchPCP;
        colFilters.pcpFirstName = this.searchPCP;
        colFilters.pcpLastName = this.searchPCP;
      }
      return colFilters;
    },
    filterByStatus(status) {
      this.$refs["vgt-table"].reset();
      status ? this.updateParams({ page: 1, columnFilters: { enrollmentStatus: status } }) : this.updateParams({ page: 1, columnFilters: { enrollmentStatus: "" } });
      this.loadItems(true, false);
    },
    async loadItems(showPageLoading, showTableLoading) {
      showPageLoading ? (this.isLoading = true) : "";
      showTableLoading ? (this.isTableLoading = true) : "";
      const method = "post";
      const path = document.config.programsApi + this.$route.params.program_id + "/roster";
      const payload = this.serverParams;

      httpSend({ path, method, payload, authToken: true })
        .then(response => {
          this.rows = response.data.results;
          this.totalRecords = response.data.totalRecords;
          this.isLoading = false;
          this.isTableLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load Patient Roster",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    onRowClick(params) {
      this.$router.push({ path: "roster/" + params.row.beneficiaryId });
    },
    makeName(rowObj) {
      return rowObj.beneLastName + ", " + rowObj.beneFirstName;
    },
    makePcpName(rowObj) {
      let title = rowObj.pcpTitle ? rowObj.pcpTitle + " " : "";
      let fName = rowObj.pcpFirstName ? rowObj.pcpFirstName + " " : "";
      let lName = rowObj.pcpLastName ? rowObj.pcpLastName + " " : "";
      return title + fName + lName;
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems(false, true);
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems(false, true);
    },
    onSortChange(params) {
      if (params.field == "beneLastName") {
        params[1] = new {
          field: "beneFirstName",
          type: params[0].type
        }();
      } else if (params.field == "pcpLastName") {
        params[1] = new {
          field: "pcpFirstName",
          type: params[0].type
        }();
      }
      this.updateParams({
        sort: params
      });
      this.loadItems(false, true);
    },
    onSearch(params) {
      this.updateParams({
        page: 1,
        columnFilters: {
          beneFirstName: params.searchTerm,
          beneLastName: params.searchTerm,
          mrn: params.searchTerm,
          dob: params.searchTerm
        }
      });
      this.loadItems(false, true);
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems(false, true);
    },
    showPopover: function(ev, currentUser) {
      this.$ionic.popoverController
        .create({
          component: PopoverRosterProgram,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: {
              programId: this.$route.params.program_id,
              currentUser: currentUser
            }
          }
        })
        .then(m => m.present());
    },
    exportRoster: function() {
      this.isDownloading = true;
      const method = "post";
      const path = document.config.rosterDownload + this.$route.params.program_id;

      this.serverParams.perPage = 50000;
      const payload = this.serverParams;
      const responseType = "blob";

      const seconds = Math.round(new Date() / 1000);

      httpSend({ path, method, payload, authToken: true, responseType })
        .then(response => {
          this.isDownloading = false;
          // force file download
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "MyPCP-Program-Roster-EXPORT-" + seconds + ".xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          this.error = error.response.data.message;
          this.isDownloading = false;
        });
    },
    goToNamedRoute(routeName) {
      this.$mypcpRouter.push({ name: routeName, params: { id: this.$route.params.program_id } });
    }
  }
};
</script>

<style scoped>
/* -------------------------------- */
/* Patient Roster ----------------- */
/* -------------------------------- */
.prime-roster-container ion-card {
  margin: 20px 0 !important;
  padding: 0;
}
.prime-roster-container ion-card ion-card-header {
  padding: 20px 20px 10px 20px;
}

.prime-roster-add-button {
  width: 160px;
  position: absolute;
  right: 40px;
  top: 90px;
}

/* Patient Roster Options Buttons -- */
.prime-roster-options-button-container button.prime-button.button-clear {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 18px;
}
.prime-roster-options-button-container button ion-icon {
  margin-right: 0;
}

/* -------------------------------- */
/* Patient Roster - Table --------- */
/* -------------------------------- */

.prime-table th,
.prime-table td {
  text-align: left;
}
.prime-table td.text-center {
  text-align: center;
}

.prime-table th {
  cursor: pointer;
}

.prime-table td {
  font-weight: 400;
}

.prime-table thead th {
  opacity: 0.25;
  transition: opacity 0.2s ease-in-out;
}

.prime-table thead th:hover {
  opacity: 0.35;
}

.prime-table th.sorting {
  cursor: pointer;
}

.prime-table thead th.sorting-asc,
.prime-table thead th.sorting-asc:hover,
.prime-table thead th.sorting-desc,
.prime-table thead th.sorting-desc:hover {
  opacity: 1;
}

.prime-table td {
  padding: 10px;
  vertical-align: middle;
  border-top: 2px solid var(--ion-color-light);
}

.prime-table td.bold {
  font-weight: 500;
}

.prime-table tr td:first-child,
.prime-table tr th:first-child {
  padding-left: 30px;
}

.prime-table tr td:last-child,
.prime-table tr th:last-child {
  padding-right: 10px;
}

.prime-table th:after {
  content: url(../../assets/sort.svg);
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 5px;
  height: 20px;
  width: 20px;
}

.prime-table th.prime-roster-nosort:after {
  content: "";
}

.prime-table th.sorting:after {
  content: url(../../assets/arrow-down.svg);
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 5px;
  height: 20px;
  width: 20px;
  transition: transform 0.2s ease-in-out;
}

.prime-table thead th.sorting-asc:after {
  transform: rotate(0deg);
}

.prime-table thead th.sorting-desc:after {
  transform: rotate(180deg);
}

.prime-roster-download-button {
  float: right !important;
  width: 180px;
}

.prime-table-left-align,
.vgt-left-align {
  text-align: left;
}

.prime-table-right-align,
.vgt-right-align {
  text-align: right;
}

.prime-table-center-align {
  text-align: center;
}

.vgt-pull-left {
  float: left !important;
}

.vgt-pull-right {
  float: right !important;
}
.vgt-clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.vgt-responsive {
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.vgt-text-disabled {
  color: #909399;
}

.vgt-wrap {
  position: relative;
}

.vgt-fixed-header {
  position: absolute;
  z-index: 10;
  width: 100%;
  overflow-x: auto;
}

table.vgt-table {
  font-size: 16px;
  border-collapse: collapse;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}

table.vgt-table tr.clickable {
  cursor: pointer;
}

table.vgt-table tr.clickable:hover {
  background-color: var(--ion-color-white-shade);
}

.vgt-table th {
  padding: 0.75em 1.5em 0.75em 0.75em;
  vertical-align: middle;
  position: relative;
}

.vgt-table th.line-numbers,
.vgt-table th.vgt-checkbox-col {
  padding: 0 0.75em 0 0.75em;
  color: #606266;
  border-right: 1px solid #dcdfe6;
  word-wrap: break-word;
  width: 25px;
  text-align: center;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.vgt-table th.filter-th {
  padding: 0.75em 0.75em 0.75em 0.75em;
}

.vgt-table th.vgt-row-header {
  border-bottom: 2px solid #dcdfe6;
  border-top: 2px solid #dcdfe6;
  background-color: #fafafb;
}

.vgt-table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dcdfe6;
  padding-right: 1.5em;
  /* background: linear-gradient(#f4f5f8, #f1f3f6) */
}

.vgt-table thead th.vgt-checkbox-col {
  vertical-align: middle;
}

.vgt-loading {
  position: absolute;
  width: 100%;
  z-index: 10;
  margin-top: 117px;
}

.vgt-loading__content {
  background-color: #c0dfff;
  color: #409eff;
  padding: 7px 30px;
  border-radius: 3px;
}

.vgt-inner-wrap.is-loading {
  opacity: 0.5;
  pointer-events: none;
}

.vgt-table.bordered td,
.vgt-table.bordered th {
  border-top: 1px solid #dcdfe6;
}

.vgt-table.bordered th.vgt-row-header {
  border-bottom: 3px solid #dcdfe6;
}

.vgt-table.striped tbody tr:nth-of-type(odd) {
  background-color: rgba(51, 68, 109, 0.03);
}

.vgt-wrap.rtl {
  direction: rtl;
}

.vgt-wrap.rtl .vgt-table td,
.vgt-wrap.rtl .vgt-table th:not(.line-numbers) {
  padding: 0.75em 0.75em 0.75em 1.5em;
}

.vgt-wrap.rtl .vgt-table thead th,
.vgt-wrap.rtl .vgt-table.condensed thead th {
  padding-left: 1.5em;
  padding-right: 0.75em;
}

.vgt-wrap.rtl .vgt-table th.sorting-asc:after,
.vgt-wrap.rtl .vgt-table th.sorting:after {
  margin-right: 5px;
  margin-left: 0;
}

.vgt-table.condensed td,
.vgt-table.condensed th.vgt-row-header {
  padding: 0.4em 0.4em 0.4em 0.4em;
}

.vgt-global-search {
  padding: 5px 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  border: 1px solid #dcdfe6;
  border-bottom: 0;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.vgt-global-search__input {
  position: relative;
  padding-left: 40px;
  flex-grow: 1;
}

.vgt-global-search__input .input__icon {
  position: absolute;
  left: 0;
  max-width: 32px;
}

.vgt-global-search__input .input__icon .magnifying-glass {
  margin-top: 3px;
  margin-left: 8px;
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid #d6dae2;
  position: relative;
  border-radius: 50%;
}

.vgt-global-search__input .input__icon .magnifying-glass:before {
  content: "";
  display: block;
  position: absolute;
  right: -7px;
  bottom: -5px;
  background: #d6dae2;
  width: 8px;
  height: 4px;
  border-radius: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.vgt-global-search__actions {
  margin-left: 10px;
}

.vgt-selection-info-row {
  background: #fdf9e8;
  padding: 5px 16px;
  font-size: 13px;
  border-top: 1px solid #dcdfe6;
  border-left: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  color: #d3aa3b;
  font-weight: 700;
}

.vgt-selection-info-row a {
  font-weight: 700;
  display: inline-block;
  margin-left: 10px;
}

.vgt-wrap__actions-footer {
  border: 1px solid #dcdfe6;
}

.vgt-wrap__footer {
  color: var(--ion-color-medium-shade);
  padding: 20px;
  background: var(--ion-color-light-tint);
}

.vgt-wrap__footer .footer__row-count__label,
.vgt-wrap__footer .footer__row-count__select {
  display: inline-block;
  vertical-align: middle;
}

.vgt-wrap__footer .footer__row-count__label {
  font-size: 14px;
  color: #909399;
}

.vgt-wrap__footer .footer__row-count__select {
  background-color: transparent;
  width: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  height: auto;
  font-size: 14px;
  margin-left: 8px;
  color: var(--ion-color-medium-shade);
  font-weight: 700;
}

.vgt-wrap__footer .footer__row-count__select:focus {
  outline: 0;
  border-color: var(--ion-color-primary);
}

.vgt-wrap__footer .footer__navigation {
  font-size: 14px;
}

.vgt-wrap__footer .footer__navigation__info,
.vgt-wrap__footer .footer__navigation__page-btn,
.vgt-wrap__footer .footer__navigation__page-info {
  display: inline-block;
  vertical-align: middle;
}

.vgt-wrap__footer .footer__navigation__page-btn {
  text-decoration: none;
  color: var(--ion-color-dark);
  font-weight: 700;
  white-space: nowrap;
}

.vgt-wrap__footer .footer__navigation__page-btn:focus {
  outline: 0;
  border: 0;
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled,
.vgt-wrap__footer .footer__navigation__page-btn.disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.left:after,
.vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.left:after {
  border-right-color: var(--ion-color-dark);
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.right:after,
.vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.right:after {
  border-left-color: var(--ion-color-dark);
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  width: 24px;
  height: 24px;
  border-radius: 15%;
  position: relative;
  margin: 0 8px;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron:after {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after {
  border-right: 6px solid var(--ion-color-primary);
  margin-left: -3px;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after {
  border-left: 6px solid var(--ion-color-primary);
  margin-left: -3px;
}

.vgt-wrap__footer .footer__navigation__info,
.vgt-wrap__footer .footer__navigation__page-info {
  display: inline-block;
  color: #909399;
  margin: 0 16px;
}

.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  width: 30px;
  text-align: center;
  display: inline-block;
  margin: 0 10px;
  font-weight: 700;
}

.button-like-status {
  border: 1px solid rgb(241, 242, 247);
  padding: 6px;
  border-radius: 7px;
  background: rgb(241, 242, 247);
  width: 200px;
  font-weight: 500;
  display: block;
  text-align: center;
}

@media only screen and (max-width: 750px) {
  .vgt-wrap__footer .footer__navigation__info {
    display: none;
  }
  .vgt-wrap__footer .footer__navigation__page-btn {
    margin-left: 16px;
  }
}
</style>
